import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// material
import { Box, Menu, MenuItem, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IntlActions, useTranslate } from 'react-redux-multilingual';
import { useNavigate } from 'react-router-dom';

// import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// components

import Hamburger from 'hamburger-react';
import { Link } from 'react-scroll';
import { NavigateLink, StyledThemeButton } from '../../components/styledComponents';
import './Header.css';

import { setTheme } from '../../redux/mainReducer.js';
//

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 60;
const APPBAR_DESKTOP = 80;

const RootStyle = styled('header')(({ theme }) => ({
  boxShadow: 'none',
  maxHeight: '88px',
  [theme.breakpoints.up('xs')]: {
    padding: '0 0px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 0px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 0px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 0px'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,

  [theme.breakpoints.up('xs')]: {
    minHeight: APPBAR_DESKTOP,
    padding: '0 20px'
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: APPBAR_DESKTOP,
    padding: '0 75px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_DESKTOP,
    padding: '0 100px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: '0 100px'
  }
}));

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function Navbar(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translate = useTranslate();
  const theme = useSelector((state) => state.global.theme);
  const selectedTheme = localStorage.getItem('theme');
  const handleChangeTheme = () => {
    if (theme.selected === 'dark') {
      dispatch(
        setTheme({
          selected: 'light',
          mainColors: {
            firm: '#1528FE',
            blue: '#1E89F7',
            white: '#FFFFFF',
            background: 'linear-gradient(153deg, #F5F6FA -8.85%, #E4EAFF 104.89%)',
            backgroundBox: '#F3F5FD',
            gray: '#9CA3AF',
            alternateGray: '#C6C6C6',
            error: '#FF0000'
          }
        })
      );
      localStorage.setItem('theme', 'light');
    } else {
      dispatch(
        setTheme({
          selected: 'dark',
          mainColors: {
            firm: '#1E89F7',
            blue: '#1528FE',
            white: '#FFFFFF',
            background: '#000000',
            backgroundBox: '#1B1B1B',
            gray: '#C6C6C6',
            alternateGray: '#9CA3AF',
            error: '#FF5959'
          }
        })
      );
      localStorage.setItem('theme', 'dark');
    }
  };

  useEffect(() => {
    if (selectedTheme === 'light' && theme.selected !== 'light') {
      dispatch(
        setTheme({
          selected: 'light',
          mainColors: {
            firm: '#1528FE',
            blue: '#1E89F7',
            white: '#FFFFFF',
            background: 'linear-gradient(153deg, #F5F6FA -8.85%, #E4EAFF 104.89%)',
            backgroundBox: '#F3F5FD',
            gray: '#9CA3AF',
            alternateGray: '#C6C6C6',
            error: '#FF0000'
          }
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  document.addEventListener('click', (e) => {
    e.stopPropagation();
    const className = e.target.className;
    if (openMenu && className && className !== 'header-menu' && className !== 'hamburger-react') {
      setOpenMenu(false);
    }
  });

  return (
    <RootStyle>
      <ToolbarStyle>
        <div className="header-burger-button">
          <Hamburger toggled={openMenu} toggle={setOpenMenu} size={24} hideOutline={true} />
        </div>
        <Box className={`header-menu ${openMenu ? 'header-openMenu' : ''}`}>
          <div
            className={theme.selected === 'dark' ? 'header-logo' : 'header-logo-dark'}
            onClick={() => {
              navigate(`/`);
            }}
          />
          <Box className={'header-menu-links'} sx={{ display: 'flex' }}>
            <NavigateLink
              scrollId="scrollLinkNews"
              scrollLink={
                <Link
                  id="scrollLinkNews"
                  style={{ textAlign: 'center', fontSize: '18px' }}
                  to="News"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={1000}
                />
              }
              text={`${translate('navigateTerms')}`}
            />
            <NavigateLink
              scrollId="scrollLinkAboutUs"
              scrollLink={
                <Link
                  id="scrollLinkAboutUs"
                  style={{ textAlign: 'center', fontSize: '18px' }}
                  to="AboutUs"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={1000}
                />
              }
              text={`${translate('navigateAdvantages')}`}
            ></NavigateLink>
            <NavigateLink
              scrollId="scrollLinkServices"
              scrollLink={
                <Link
                  id="scrollLinkServices"
                  style={{ textAlign: 'center', fontSize: '18px' }}
                  to="Services"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={1000}
                />
              }
              text={`${translate('navigateFunctional')}`}
            />

            {/* <NavigateLink
              scrollId="scrollLinkFAQ"
              scrollLink={
                <Link
                  id="scrollLinkFAQ"
                  style={{ textAlign: 'center', fontSize: '18px' }}
                  to="FAQ"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={1000}
                />
              }
              text="FAQ"
            /> */}
            <NavigateLink
              scrollId="scrollLinkCoordinates"
              scrollLink={
                <Link
                  id="scrollLinkCoordinates"
                  style={{ textAlign: 'center', fontSize: '18px' }}
                  to="Coordinates"
                  spy={true}
                  smooth={true}
                  offset={-20}
                  duration={1000}
                />
              }
            >
              {`${translate('navigateFAQ')}`}
            </NavigateLink>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledThemeButton onClick={handleChangeTheme} />
            <NavigateLink
              paragraph
              onClick={handleClick}
              // aria-controls={open ? 'basic-menu' : undefined}
              // aria-haspopup="true"
              // aria-expanded={open ? 'true' : undefined}
              sx={{
                display: 'contents',
                color: 'white',
                fontSize: '20px',
                textTransform: 'uppercase',
                cursor: 'pointer'
                // ':hover': {
                //   color: theme.mainColors.firm
                // }
              }}
            >
              {props.language} <KeyboardArrowDownIcon sx={{ fontSize: '30px' }} />
            </NavigateLink>
            {/* <PersonOutlinedIcon
              sx={{
                color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
                fontSize: '30px',
                marginBottom: '6px',
                marginLeft: '20px',
                marginRight: '20px',
                cursor: 'pointer',
                ':hover': {
                  color: theme.mainColors.firm
                }
              }}
            /> */}
          </Box>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              backdropFilter: ' blur(25px)',
              background:
                'radial-gradient(4905.31% 145% at 98.67% 106.25%, rgb(229 231 235) 0%, rgb(229 231 235 / 42%) 0.01%, rgb(229 231 235 / 39%) 100%)',
              boxShadow: 'none',
              borderRadius: '5px',
              border: `none`,
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              width: 'fit-content',
              mt: 1
            }
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <MenuItem
            selected={props.language === 'ua'}
            onClick={() => {
              dispatch(IntlActions.setLocale('ua'));
              navigate(`/`);
            }}
            sx={{
              padding: 0,
              color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10 !important',
              fontSize: '20px',
              '&.MuiMenuItem-root.Mui-selected': {
                color: `${theme.mainColors.firm} !important`,
                backgroundColor: 'transparent !important'
              },
              '&.MuiMenuItem-root:hover': {
                color: `${theme.mainColors.firm} !important`,
                background: 'none'
              }
            }}
          >
            {/* <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            > */}
            UA
            {/* </Typography> */}
          </MenuItem>
          <MenuItem
            sx={{
              padding: 0,
              color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10 !important',
              fontSize: '20px',
              '&.MuiMenuItem-root.Mui-selected': {
                color: `${theme.mainColors.firm} !important`,
                backgroundColor: 'transparent !important'
              },
              '&.MuiMenuItem-root:hover': {
                color: `${theme.mainColors.firm} !important`,
                background: 'none'
              }
            }}
            selected={props.language === 'en'}
            onClick={() => {
              dispatch(IntlActions.setLocale('en'));
              navigate(`/`);
            }}
          >
            {/* <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            > */}
            EN
            {/* </Typography> */}
          </MenuItem>
          <MenuItem
            selected={props.language === 'pl'}
            onClick={() => {
              dispatch(IntlActions.setLocale('pl'));
              navigate(`/`);
            }}
            sx={{
              color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10 !important',
              padding: 0,
              fontSize: '20px',
              '&.MuiMenuItem-root.Mui-selected': {
                color: `${theme.mainColors.firm} !important`,
                backgroundColor: 'transparent !important'
              },
              '&.MuiMenuItem-root:hover': {
                color: `${theme.mainColors.firm} !important`,
                background: 'none'
              }
            }}
          >
            {/* <Typography
              component="p"
              fontSize={16}
              lineHeight="18px"
              fontWeight={400}
              style={{ marginBottom: '10px' }}
            > */}
            PL
            {/* </Typography> */}
          </MenuItem>
        </Menu>
      </ToolbarStyle>
    </RootStyle>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Navbar);
