import React, { useEffect } from 'react';

import './App.css';
import RouterComponent from './routes';
import CookieModal from './components/modals/CookieModal/CookieModal';
import { setIsOpenCookiesModal } from './redux/mainReducer';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCallback } from 'react';
import BackgroundLight from './assets/backgroundLight.png';
//CURRENCY ICONS

function App() {
  const theme = useSelector((state) => state.global.theme);
  const dispatch = useDispatch();
  const body = document.body;
  body.setAttribute(
    'style',
    `background: url(${theme.selected === 'dark' ? 'black' : BackgroundLight})`
  );
  body.style.backgroundSize = 'cover';

  const isOpenCookiesModal = useSelector((state) => state.global.isOpenCookiesModal);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  useEffect(() => {
    if (
      !window.location.href.includes('politics-page') &&
      !window.location.href.includes('terms-page')
    ) {
      if (
        (!isOpenCookiesModal && !getCookie('accepted_cookies')) ||
        getCookie('accepted_cookies') === 'false'
      ) {
        dispatch(setIsOpenCookiesModal(true));
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <CookieModal isOpenModal={isOpenCookiesModal} />
      <RouterComponent />
    </React.Fragment>
  );
}

export default App;
