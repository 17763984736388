import React from 'react';
// import { useTranslate } from 'react-redux-multilingual';
// import { Element } from 'react-scroll';

//Material
import { Box, Grid, Typography } from '@mui/material';

//Components
import {  TopTitle } from '../../../components/styledComponents';



import { EffectCoverflow, Pagination } from 'swiper/modules';
import { SwiperSlide, Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';

export default function Feedbacks() {
  const theme = useSelector((state) => state.global.theme);
  // const translate = useTranslate();

  const mockData = [
    {
      nickname: 'Yura',
      title: '(обменял 109 Tether TRC20 на 4125.45 PRIVAT24 (Приват))',
      time: '22.07.2023',
      feedback: '“Все пройшло успішно.Рекомендую”'
    },
    {
      nickname: 'Yura',
      title: '(обменял 109 Tether TRC20 на 4125.45 PRIVAT24 (Приват))',
      time: '22.07.2023',
      feedback: '“Все пройшло успішно.Рекомендую”'
    },
    {
      nickname: 'Yura',
      title: '(обменял 109 Tether TRC20 на 4125.45 PRIVAT24 (Приват))',
      time: '22.07.2023',
      feedback: '“Все пройшло успішно.Рекомендую”'
    },
    {
      nickname: 'Yura',
      title: '(обменял 109 Tether TRC20 на 4125.45 PRIVAT24 (Приват))',
      time: '22.07.2023',
      feedback: '“Все пройшло успішно.Рекомендую”'
    },
    {
      nickname: 'Yura',
      title: '(обменял 109 Tether TRC20 на 4125.45 PRIVAT24 (Приват))',
      time: '22.07.2023',
      feedback: '“Все пройшло успішно.Рекомендую”'
    },
  ];
  // const [screenType, setScreenType] = useState('desktop');
  // useEffect(() => {
  //   if (window.screen.width <= 900) {
  //     setScreenType('mobile');
  //   }
  // }, []);
 

  return (
    <Grid
      item
      lg={12}
      sx={{
        pt: '100px',
        pb: '35px',
        paddingLeft: { xs: '20px', sm: '75px', md: '100px', lg: '100px' },
        paddingRight: { xs: '20px', sm: '75px', md: '100px', lg: '100px' }
      }}
    >
      <TopTitle variant="h1">Відгуки</TopTitle>
      <Box sx={{ maxWidth: '100%' }}>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false
          }}
          pagination={{
            clickable: true,
            // renderBullet: function (index, className) {
            //   return '<span class="' + className + '">' + (index + 1) + '</span>';
            // },
          }}
          modules={[EffectCoverflow,Pagination]}
          className="mySwiper"
        >
          {mockData.map((feedback, index) => (
            <SwiperSlide key={`slideFeedback${index}`}>
              <Box
                sx={{
                  p: 2,
                  background: theme.mainColors.backgroundBox,
                  borderRadius: '16px',
                  ':before': {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    borderRadius: '16px',
                    border: '1px solid transparent',
                    background: 'linear-gradient(45deg,#424244,#1B1B1B) border-box;',
                    WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);',
                    WebkitMaskComposite: 'destination-out'
                  }
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>{feedback.nickname}</Typography>
                  <Typography sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>{feedback.time}</Typography>
                </Box>
                <Typography sx={{ mb: 2,color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10' }}>{feedback.title}</Typography>
                <Typography sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>{feedback.feedback}</Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Grid>
  );
}
