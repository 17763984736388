import React from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';

import { Grid } from '@mui/material';
import Exchange from './Blocks/Exchange.jsx';
import Partners from './Blocks/Partners.jsx';
import Feedbacks from './Blocks/Feedbacks.jsx';
import News from './Blocks/News';

function MainPage({ language }) {
  const theme = useSelector((state) => state.global.theme);
  const dispatch = useDispatch();


  return (
    <Page style={{ height: '100%' }} title={`CryptoRacoonBot`}>
      <Grid container spacing={0}>

        {/* <Exchange />
        <Partners />
        <Feedbacks />
        <News /> */}
      </Grid>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};

export default connect(mapStateToProps)(MainPage);
