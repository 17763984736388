import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
//Pages
import MainLayout from './layouts';
import MainPage from './pages/MainPage';

// ----------------------------------------------------------------------

function Router({ language }) {
  // const user = useSelector((state) => state.global.userData);
  // const token = localStorage.getItem('accessToken');

  // const ProtectedRoute = ({ user, children }) => {
  //   if ((!user && !token) || (Object.keys(user).length === 0 && !token)) {
  //     return <Navigate to={'/' + language} replace exact />;
  //   }

  //   return children;
  // };
  return useRoutes([
    { path: '/', element: <Navigate to={'/' + language} exact /> },
    {
      path: '/:lng',
      element: <MainLayout />,
      children: [
        { path: '/:lng/', element: <MainPage /> }
        // { path: '/:lng/news-page', element: <NewsPage /> },
        // { path: '/:lng/service-page', element: <ServicePage /> },
        // { path: '/:lng/terms-page', element: <TermsPage /> },
        // { path: '/:lng/politics-page', element: <PoliticsPage /> },
        // { path: '/:lng/user-page', element: <UserPage /> }
        // { path: '/:lng/search', element: <SearchPage /> },
        // { path: '/:lng/service/:id', element: <ServicePage /> },
        // { path: '/:lng/post/:id', element: <PostPage /> },
        // { path: '/:lng/messages', element: <MessagesPage /> },
        // {
        //   path: '/:lng/user-page/:id',
        //   element: (
        //     <ProtectedRoute user={user} token={token}>
        //       <UserPage />
        //     </ProtectedRoute>
        //   )
        // },
        // { path: '404', element: <Page404 /> },
        // { path: '403', element: <Page404 /> },
        // { path: '*', element: <Navigate to={'/' + language + '/404'} replace /> }
      ]
    }
  ]);
}
const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale
  };
};
export default connect(mapStateToProps)(Router);
