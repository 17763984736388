import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import mainReducer from './redux/mainReducer';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Slide, ThemeProvider, createTheme } from '@mui/material';
import translations from './services/translations';

const reducers = combineReducers(Object.assign({}, { Intl, global: mainReducer }));
const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(reducers, { Intl: { locale: 'ua' } }, applyMiddleware(thunk));
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 390,
      sm: 600,
      md: 1140,
      lg: 1440
    }
  }
});
// document.cookie = 'accepted_cookies=false';
// console.log(getCookie('accepted_cookies'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider translations={translations}>
        <HelmetProvider>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              TransitionComponent={Slide}
              autoHideDuration={2000}
            >
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </HelmetProvider>
      </IntlProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
