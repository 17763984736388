const theme = {
  mainColors: {
    firm: '#1e89f7',
    buttonLinear: '#4DF3DC',
    white: '#FFFFFF',
    background: '#0F0F10',
    backgroundBox: '#1B1B1B',
    popUp: '#242424F2',
    gray: '#9CA3AF',
    error: 'rgba(255, 89, 89, 1)'
  }
};
export default theme;
