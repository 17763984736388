// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finlab_exchange_modal:focus {
  outline: none !important;
}

.finlab_exchange_modal-box:focus-visible {
  outline: none !important;
}

.finlab_exchange_modal-close {
  transition: all 1.5s;
}
.finlab_exchange_modal-close:hover {
  transform: rotate(360deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/CookieModal/CookieModal.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".finlab_exchange_modal:focus {\n  outline: none !important;\n}\n\n.finlab_exchange_modal-box:focus-visible {\n  outline: none !important;\n}\n\n.finlab_exchange_modal-close {\n  transition: all 1.5s;\n}\n.finlab_exchange_modal-close:hover {\n  transform: rotate(360deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
