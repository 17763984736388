import { createSlice } from '@reduxjs/toolkit';

const mainReducer = createSlice({
  name: 'mainReducer',
  initialState: {
    isOpenSearchBar: false,
    isOpenExchangeOrderModal: {
      isOpen: false,
      firstCurrency: 'USD',
      secondCurrency: 'USDT',
      firstCurrencyQTT: 0,
      secondCurrencyQTT: 0
    },
    theme: {
      selected: 'dark',
      mainColors: {
        firm: '#1E89F7',
        blue: '#1528FE',
        white: '#FFFFFF',
        background: '#000000',
        backgroundBox: '#1B1B1B',
        gray: '#C6C6C6',
        alternateGray: '#9CA3AF',
        error: '#FF5959'
      }
    },

    isOpenCookiesModal: false
  },
  reducers: {
    setIsOpenSearchBar: (state, action) => {
      state.isOpenSearchBar = action.payload;
    },
    setIsOpenExchangeOrderModal: (state, action) => {
      state.isOpenExchangeOrderModal = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setIsOpenCookiesModal: (state, action) => {
      state.isOpenCookiesModal = action.payload;
    }
  }
});

export const { setIsOpenSearchBar, setIsOpenExchangeOrderModal, setTheme, setIsOpenCookiesModal } =
  mainReducer.actions;
//
export default mainReducer.reducer;
