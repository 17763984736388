import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Link, Modal, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';

// import { useTranslate } from 'react-redux-multilingual';
import CookieGif from '../../../assets/Cookie.gif';
import { setIsOpenCookiesModal } from '../../../redux/mainReducer';
import theme from '../../../services/theme';
import {
  StyledButton
} from '../../styledComponents';
import './CookieModal.css';
const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: { xs: '100%', sm: '80%', md: '80%', lg: '80%' },
  bgcolor: theme.mainColors.background,
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: { xs: '20px', sm: '60px', md: '60px', lg: '60px' },
  overflow: 'scroll',
  maxHeight: { xs: '96vh', sm: '96vh', md: '90vh', lg: '90vh' },
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function CookieModal({ isOpenModal, language }) {
  const dispatch = useDispatch();

  // const translate = useTranslate();

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenCookiesModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="finlab_exchange_modal"
      >
        <Box sx={style} className="finlab_exchange_modal-box">
          <CloseIcon
            className="finlab_exchange_modal-close"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
              color: theme.mainColors.white,
              zIndex: '99'
            }}
            onClick={() => {
              dispatch(setIsOpenCookiesModal(false));
            }}
          />
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={CookieGif} width={60} alt="cookie" />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                Ми та наші цифрові партнери використовуємо файли cookie, щоб покращити ваш досвід
                перегляду, зберегти ваші налаштування та надати нам інформацію про те, як ви
                використовуєте наш веб-сайт. Для отримання додаткової інформації про файли cookie
                дивіться нашу
              </Typography>
              <Link
                href={`/${language}/politics-page`}
                onClick={() => dispatch(setIsOpenCookiesModal(false))}
                sx={{
                  fontSize: '20px',
                  textDecoration: 'underline',
                  marginTop: '10px',
                  marginBottom: '15px',
                  cursor: 'pointer',
                  color: theme.mainColors.white,
                  '&.MuiLink-root:hover': {
                    color: theme.mainColors.firm
                  }
                }}
              >
                Політику конфіденційності
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  maxWidth: '550px',
                  width: { xs: '30%', sm: '45%', md: '55%', lg: '55%' },
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                  alignItems: { xs: 'center', sm: 'center', md: 'row', lg: 'row' },
                  justifyContent: 'space-between'
                }}
              >
                <StyledButton
                  onClick={() => {
                    document.cookie = 'accepted_cookies=true';
                    dispatch(setIsOpenCookiesModal(false));
                  }}
                  width={{ xs: '185px', sm: '185px', md: '253px', lg: '253px' }}
                  text="Дозволити"
                />
                <StyledButton
                  onClick={() => {
                    document.cookie = 'accepted_cookies=false';
                    dispatch(setIsOpenCookiesModal(false));
                  }}
                  width={{ xs: '185px', sm: '185px', md: '253px', lg: '253px' }}
                  text="Не дозволити"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(CookieModal);
