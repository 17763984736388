import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Box, Button, Paper, TextField, Typography } from '@mui/material';

import ReverseBtn from '../assets/Reverse.png';
import { useSelector } from 'react-redux';
import { BotSVG, DarkSVG, LightSVG } from './icons';
import { useState } from 'react';

export const TopTitle = ({ children, variant = 'h1', width = '100%', ...props }) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <Typography
      {...props}
      sx={() => {
        if (variant === 'h1') {
          return {
            color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
            textAlign: 'center',
            fontSize: {
              xs: '24px',
              sm: '36px',
              md: '36px',
              lg: '36px'
            },
            fontWeight: '500',
            position: 'relative',
            width: width
          };
        }
        if (variant === 'h2') {
          return {
            color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
            textAlign: 'left',
            zIndex: 0,
            fontSize: {
              xs: '22px',
              sm: '24px',
              md: '30px',
              lg: '32px'
            },
            fontWeight: '500'
          };
        }
      }}
    >
      {variant === 'h1' && children}
      {variant === 'h2' && children}
    </Typography>
  );
};
export const NavigateLink = ({
  onClick,
  children,
  scrollLink,
  scrollId,
  text,
  botIcon = false,
  ...props
}) => {
  const theme = useSelector((state) => state.global.theme);
  const [hover, setHover] = useState(false);

  return (
    <Button
      disableRipple
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        padding: '10px',
        cursor: 'pointer',
        color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
        fontSize: '20px',
        lineHeight: '25.14px',
        textTransform: children ? 'uppercase' : 'none',
        minWidth: 'unset',
        position: 'relative'
      }}
      sx={{
        ':hover': {
          color: `${theme.mainColors.firm} !important`
        }
      }}
      onClick={
        !scrollLink
          ? onClick
          : (e) => {
              const scroll = document.getElementById(scrollId);
              scroll.click();
            }
      }
    >
      {scrollLink && scrollLink}
      {botIcon && <BotSVG style={{ marginRight: '5px' }} hover={hover} />}
      {children ? children : text}
    </Button>
  );
};
export const StyledTextField = ({
  onChange,
  disableBorder = false,
  width = { xs: '288px', sm: '405px', md: '405px', lg: '405px' },
  disabled = false,
  ...props
}) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <TextField
      multiline
      disabled={disabled}
      {...props}
      onChange={onChange}
      sx={{
        width: width,
        '& .MuiInputBase-input': {
          color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
          height: '12px',
          fontFamily: ''
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.mainColors.gray
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
          '& fieldset': {
            border: disableBorder
              ? 'none'
              : theme.selected === 'dark'
              ? `1px solid ${theme.mainColors.gray}`
              : `1px solid ${theme.mainColors.alternateGray}`,
            borderRadius: '16px',
            color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'
          },
          '&:hover fieldset': {
            borderColor: theme.mainColors.gray
          }
        },
        '& .MuiOutlinedInput-root': {
          height: '80px',
          display: 'flex',
          alignItems: 'center',
          '& fieldset': {
            borderColor: theme.mainColors.error,
            border: disableBorder
              ? 'none'
              : theme.selected === 'dark'
              ? `1px solid ${theme.mainColors.gray}`
              : `1px solid ${theme.mainColors.gray}`,
            borderRadius: '16px',
            color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'
          },
          '&:hover fieldset': {
            borderColor: theme.mainColors.firm
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.mainColors.firm
          }
        }
      }}
    />
  );
};

export const StyledAutocomplete = ({
  options,
  width = '110px',
  disableBorder = true,
  disableWrite = false,
  ...props
}) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <Autocomplete
      {...props}
      // disableCloseOnSelect
      onKeyPress={(e) => {
        e.preventDefault();
      }}
      disableClearable
      options={options}
      getOptionLabel={(option) => `${option}`}
      sx={{
        '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
          top: '20px !important'
        },
        '& .MuiTextField-root': {
          margin: '0'
        }
      }}
      popupIcon={
        <KeyboardArrowDownIcon
          sx={{
            fontSize: '30px',
            color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
            background: 'none',
            ':hover': {
              color: theme.mainColors.firm
            }
          }}
        />
      }
      renderInput={(params) => (
        <StyledTextField
          disabled={disableWrite}
          disableBorder={disableBorder}
          width={width}
          {...params}
        />
      )}
      PaperComponent={({ children }) => (
        <Paper
          style={{
            backdropFilter: ' blur(25px)',
            background:
              'radial-gradient(4905.31% 145% at 98.67% 106.25%, rgb(229 231 235) 0%, rgb(229 231 235 / 42%) 0.01%, rgb(229 231 235 / 39%) 100%)'
          }}
          sx={{
            overflow: 'hidden',
            background:
              'radial-gradient(4905.31% 145% at 98.67% 106.25%, rgb(229 231 235) 0%, rgb(229 231 235 / 42%) 0.01%, rgb(229 231 235 / 39%) 100%)',
            boxShadow: 'none',
            borderRadius: '5px',
            border: `none ! important`,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            width: 'fit-content',
            mt: 1,
            backdropFilter: ' blur(25px)'
          }}
        >
          {children}
        </Paper>
      )}
      renderOption={(props, option, state, index) => {
        return (
          <Typography
            {...props}
            sx={{
              background: 'transparent !important',
              color: state.selected
                ? theme.mainColors.firm
                : theme.selected === 'dark'
                ? theme.mainColors.white
                : '#0F0F10',
              fontWeight: state.selected ? '700 !important' : '400 !important',
              ':hover': {
                color: theme.mainColors.firm
              }
            }}
          >
            {option}
          </Typography>
        );
      }}
    />
  );
};
export const StyledButton = ({
  withoutBorders = false,
  text,
  width = { xs: '207px', sm: '265px', md: '405px', lg: '464px' },
  icon,
  ...props
}) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <Button
      {...props}
      sx={{
        width: width,
        mt: 4,
        color: theme.selected === 'dark' ? theme.mainColors.white : theme.mainColors.firm,
        fontWeight: '600',
        padding: '14.5px ',
        lineHeight: 'normal',
        border:
          theme.selected === 'dark' ? `2px solid #F5F6FA` : `2px solid ${theme.mainColors.firm}`,
        borderRadius: '8px',
        textTransform: 'none',
        fontSize: { xs: '20px', sm: '20px', md: '24px', lg: '24px' },
        ':hover': {
          color: theme.selected === 'dark' ? theme.mainColors.white : theme.mainColors.white,
          border:
            theme.selected === 'dark' ? `2px solid rgba(0, 117, 255, 0.40)` : '2px solid white',
          background: theme.mainColors.firm,
          textDecoration: !withoutBorders ? 'none' : 'underline'
        }
      }}
    >
      {text}
      {icon && icon}
    </Button>
  );
};
export const StyledThemeButton = (props) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <Box
      {...props}
      sx={{
        zIndex: '999',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        marginLeft: 'calc(100% - 150px)',
        padding: '4px ',
        border: `none`,
        borderRadius: '8px',
        background:
          theme.selected === 'dark'
            ? 'linear-gradient(315deg, rgba(0,0,0,1) 0%, rgba(140,137,137,0.892192594811362) 100%, rgba(0,0,0,0) 100%)'
            : 'linear-gradient(131deg, rgba(21,40,254,0.4664222866881127) 0%, rgba(21,40,254,0.4608200457917542) 0%, rgba(21,40,254,0.08546990573573177) 100%)',
        ':hover': {
          border: `none`,
          background:
            theme.selected === 'dark'
              ? 'linear-gradient(315deg, rgba(0,0,0,1) 0%, rgba(140,137,137,0.892192594811362) 100%, rgba(0,0,0,0) 100%)'
              : 'linear-gradient(131deg, rgba(21,40,254,0.4664222866881127) 0%, rgba(21,40,254,0.4608200457917542) 0%, rgba(21,40,254,0.08546990573573177) 100%)'
        }
      }}
    >
      {theme.selected === 'dark' ? <LightSVG /> : <DarkSVG />}
    </Box>
  );
};
export const StyledButtonReverse = ({ left, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        position: 'absolute',
        left: left,
        width: '60px',
        height: '60px',
        minWidth: '60px',
        p: '0 !important',
        lineHeight: 'normal',
        background: `url(${ReverseBtn})`,
        border: 'none',
        backgroundPosition: 'center',
        borderRadius: '30px',
        ':hover': { transform: 'scale(0.95)' }
      }}
    />
  );
};
export const SimpleButton = ({
  text,
  display = 'unset',
  width = 'fit-content',
  isNoMargin = false,
  fontSize = '20px',
  icon,
  ...props
}) => {
  const theme = useSelector((state) => state.global.theme);
  return (
    <Button
      {...props}
      variant="text"
      sx={{
        display: display,
        width: width,
        mt: isNoMargin ? 0 : 2,
        mb: isNoMargin ? 0 : 2,
        pl: 0,
        color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
        fontWeight: '400',

        lineHeight: 'normal',
        textTransform: 'none',
        // textDecoration: 'underline',
        fontSize: fontSize,
        ':hover': {
          color: theme.mainColors.firm,
          background: 'transparent',
          textDecoration: 'underline'
        }
      }}
    >
      {text}
      {icon && icon}
    </Button>
  );
};
