import React from 'react';
// import { useTranslate } from 'react-redux-multilingual';
// import { Element } from 'react-scroll';

//Material
import { Grid } from '@mui/material';

//Components
import { StyledButton, TopTitle } from '../../../components/styledComponents';

import { Element } from 'react-scroll';
import Vector from '../../../assets/Blocks/News/Vector.png';
import { useSelector } from 'react-redux';

export default function News() {
  // const translate = useTranslate();
  const theme = useSelector((state) => state.global.theme);

  return (
    <Grid
      item
      lg={12}
      sx={{
        pt: '60px',
        pb: '50px',
        paddingLeft: { xs: '20px', sm: '75px', md: '100px', lg: '100px' },
        paddingRight: { xs: '20px', sm: '75px', md: '100px', lg: '100px' }
      }}
    >
      <Element name="News" />

      <Grid
        container
        spacing={0}
        sx={{
          mt: 10,
          mb: 2,
          p: 10,
          background: theme.mainColors.backgroundBox,
          borderRadius: '16px',
          position: 'relative',
          ':before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: '16px',
            border: '1px solid transparent',
            background: 'linear-gradient(45deg,#424244,#1B1B1B) border-box;',
            WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);',
            WebkitMaskComposite: 'destination-out'
          }
        }}
      >
        <Grid item lg={8}>
          <TopTitle variant="h2">
            Приєднуйся до CryptoBit комюніті сьогодні та отримуй кльові плюшки для новачків.
          </TopTitle>
          <StyledButton
            width={{ xs: '217px', sm: '250px', md: '325px', lg: '325px' }}
            text="Приєднатися"
          />
        </Grid>
        <Grid item lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{
              minWidth: '60px',
              width: '260px',
              height: '260px'
            }}
            src={Vector}
            alt="vector"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
