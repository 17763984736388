import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// material
// import { alpha, styled } from '@mui/material/styles';
import { Box, Grid, Link, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components

//
import { useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../components/styledComponents';
import './Footer.css';

import Mail from '../../assets/socialIcons/e-mail.png';
import Facebook from '../../assets/socialIcons/facebook.png';
import Instagram from '../../assets/socialIcons/instagram.png';
import LinkedIn from '../../assets/socialIcons/linkedin.png';
import Telegram from '../../assets/socialIcons/telegram.png';
import Twitter from '../../assets/socialIcons/twitter.png';
import MailDark from '../../assets/socialIcons/dark/e-mail.png';
import FacebookDark from '../../assets/socialIcons/dark/facebook.png';
import InstagramDark from '../../assets/socialIcons/dark/instagram.png';
import LinkedInDark from '../../assets/socialIcons/dark/linkedin.png';
import TelegramDark from '../../assets/socialIcons/dark/telegram.png';
import TwitterDark from '../../assets/socialIcons/dark/twitter.png';
import { PhoneSVGLittle, TelegramSVGLittle } from '../../components/icons';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 470;
const APPBAR_DESKTOP = 240;

const RootStyle = styled('footer')(({ theme }) => ({
  boxShadow: 'none',
  [theme.breakpoints.up('xs')]: {
    padding: '0 20px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 40px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 100px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 100px'
  }

  // background:
  //   'linear-gradient(0deg, rgba(255,255,255,0.2987570028011205) 0%, rgba(9,121,13,0.5032387955182073) 50%, rgba(255,255,255,0.3) 100%);'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('xs')]: {
    alignItems: 'start',
    minHeight: APPBAR_MOBILE,
    padding: '60px 0px 00px 0px'
  },
  [theme.breakpoints.up('sm')]: {
    alignItems: 'start',
    minHeight: APPBAR_MOBILE,
    padding: '60px 0px 40px 0px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: '60px 0px 60px 0px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: '60px 0px 60px 0px'
  }
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function Footer() {
  const language = useSelector((state) => state.Intl.locale);
  const navigate = useNavigate();
  const theme = useSelector((state) => state.global.theme);
  return (
    <RootStyle>
      <ToolbarStyle>
        <Grid container sx={{ height: APPBAR_DESKTOP }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                mr: 5,
                width: '300px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Link
                sx={{
                  display: 'flex',
                  fontSize: '16px',
                  textDecoration: 'none',
                  marginBottom: '15px',
                  color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
                  '&.MuiLink-root:hover': {
                    color: theme.mainColors.firm
                  }
                }}
                href="tel:+380662500001"
              >
                <PhoneSVGLittle />
                +380662500001
              </Link>
              <Link
                sx={{
                  display: 'flex',
                  fontSize: '16px',
                  textDecoration: 'none',
                  marginBottom: '15px',
                  color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',
                  '&.MuiLink-root:hover': {
                    color: theme.mainColors.firm
                  }
                }}
                href="tel:+380662500001"
              >
                <TelegramSVGLittle />
                @CryptoBit_CS
              </Link>
            </Box>
            <Box
              sx={{
                width: '252px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
                // marginTop: { xs: '25px', sm: '25px', md: '65px', lg: '75px' }
              }}
            >
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? Instagram : InstagramDark} alt="insta" />
              </a>
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? Telegram : TelegramDark} alt="telegram" />
              </a>
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? LinkedIn : LinkedInDark} alt="linkedin" />
              </a>
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? Facebook : FacebookDark} alt="facebook" />
              </a>
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? Twitter : TwitterDark} alt="twitter" />
              </a>
              <a
                style={{ fontSize: '16px', textDecoration: 'none', marginBottom: '15px' }}
                href="/"
                fontSize={20}
              >
                <img src={theme.selected === 'dark' ? Mail : MailDark} alt="mail" />
              </a>
            </Box>
            {/* <div
              className={theme.selected === 'dark' ? 'footer-logo' : 'footer-logo-dark'}
              onClick={() => {
                navigate(`/`);
              }}
            ></div> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: { xs: 'baseline', sm: 'baseline', md: 'center', lg: 'center' }
            }}
          >
            <Box
              sx={{
                width: { xs: '900px', sm: '900px', md: '900px', lg: '900px' },
                m: '0 auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <SimpleButton
                display={{ xs: 'none', sm: 'none', md: 'unset', lg: 'unset' }}
                isNoMargin
                text="FAQ"
              />
              <SimpleButton
                display={{ xs: 'none', sm: 'none', md: 'unset', lg: 'unset' }}
                isNoMargin
                text="Партнерська програма"
              />
              <SimpleButton
                onClick={() => navigate(`/${language}/politics-page`)}
                isNoMargin
                text="Правила"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: { xs: 'baseline', sm: 'baseline', md: 'center', lg: 'center' }
            }}
          >
            <Box
              sx={{
                width: { xs: '600px', sm: '600px', md: '600px', lg: '600px' },
                m: '0 auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {' '}
              <Typography
                sx={{
                  // display: { xs: 'unset', sm: 'unset', md: 'none', lg: 'none' },
                  color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'
                }}
                fontSize={16}
              >
                ©️ CryptoBit 2023
              </Typography>
              <SimpleButton
                onClick={() => navigate(`/${language}/terms-page`)}
                // display={{ xs: 'unset', sm: 'unset', md: 'none', lg: 'none' }}
                isNoMargin
                fontSize="16px"
                text="Політіки конфідеційності"
              />
            </Box>
          </Grid>
        </Grid>
      </ToolbarStyle>
    </RootStyle>
  );
}
