// eslint-disable-next-line
export default {
  ua: {
    locale: 'ua',
    messages: {
      navigateAdvantages: 'Переваги',
      navigateFunctional: 'Функціонал',
      navigateTerms: 'Умови користування',
      navigateFAQ: 'FAQ'
    }
  },
  en: {
    locale: 'en',
    messages: {
      navigateAdvantages: 'Advantages',
      navigateFunctional: 'Functional',
      navigateTerms: 'Terms of use',
      navigateFAQ: 'FAQ'
    }
  },
  pl: {
    locale: 'pl',
    messages: {
      navigateAdvantages: 'Zalety',
      navigateFunctional: 'Funkcjonalny',
      navigateTerms: 'Warunki korzystania',
      navigateFAQ: 'FAQ'
    }
  }
};
