import React from 'react';
// import { useTranslate } from 'react-redux-multilingual';
// import { Element } from 'react-scroll';

//Material
import { Grid, Stack, Typography } from '@mui/material';

//Components
import { useDispatch, useSelector } from 'react-redux';
import ExchangeOrderModal from '../../../components/modals/ExchangeModal/ExchangeOrderModal';
import {
  StyledAutocomplete,
  StyledButton,
  StyledButtonReverse,
  StyledTextField,
  TopTitle
} from '../../../components/styledComponents';
import { setIsOpenExchangeOrderModal } from '../../../redux/mainReducer';
import { useState } from 'react';
import { useEffect } from 'react';


export default function Exchange() {
  const [isLoaded, setIsLoaded]=useState(false)
  // const translate = useTranslate();
  const dispatch = useDispatch();
  const isOpenExchangeOrdermodal = useSelector((state) => state.global.isOpenExchangeOrderModal);
  const theme = useSelector((state) => state.global.theme);
  // const [screenType, setScreenType] = useState('desktop');
  useEffect(() => {
    setIsLoaded(true)
    return ()=>{
      setIsLoaded(false)
    }
  }, []);
  return (
    <Grid
      item
      lg={12}
      sx={{
        pt: { xs: '20px', sm: '30px', md: '50px', lg: '50px' },
        pb: '100px',
        paddingLeft: { xs: '20px', sm: '75px', md: '100px', lg: '100px' },
        paddingRight: { xs: '20px', sm: '75px', md: '100px', lg: '100px' }
      }}
    >
      <ExchangeOrderModal
        isOpenModal={isOpenExchangeOrdermodal.isOpen}
        data={{
          firstCurrency: isOpenExchangeOrdermodal.firstCurrency,
          secondCurrency: isOpenExchangeOrdermodal.secondCurrency,
          firstCurrencyQTT: isOpenExchangeOrdermodal.firstCurrencyQTT,
          secondCurrencyQTT: isOpenExchangeOrdermodal.secondCurrencyQTT
        }}
      />
      {/* <Element name="Exchange" /> */}
      <Grid
        container
        spacing={4}
        sx={{
          width:'100%',
          m:0,
          p: 5,
          background:theme.mainColors.backgroundBox,
          borderRadius: '16px',
          position: 'relative',
          transform: isLoaded?'rotate(0turn)':'rotate(-0.015turn)',
          transition: 'transform 1s ease-in-out',
          // ':hover': {
          //   transform: 'rotate(0turn)',
          //   transition: 'transform 1s ease-in-out'
          // },
          // ':focus': { transform: 'rotate(0turn)', transition: 'transform 1s ease-in-out' },
          ':before': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: '16px',
            border: '1px solid transparent',
            background: 'linear-gradient(45deg,#424244,#1B1B1B) border-box;',
            WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);',
            WebkitMaskComposite: 'destination-out'
          }
        }}
      >
        <TopTitle variant="h1">Що бажаєте обміняти?</TopTitle>

        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', pl:"0 !important" }}>
        <Typography sx={{mb:2, ml:1,color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10',}}>Віддаю</Typography>
          <Stack
            sx={{
              pr:2.5,
              display: 'flex',
              height:'80px',
              flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'column' },
              margin: '0px 0px 15px 0px',
              justifyContent:'center',
              borderRadius:'8px',
              background:
                'radial-gradient(4905.31% 145% at 98.67% 106.25%, rgba(229, 231, 235, 0.16) 0%, rgba(229, 231, 235, 0.20) 0.01%, rgba(229, 231, 235, 0.08) 100%)'
            }}
          >
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledAutocomplete
              
                value={isOpenExchangeOrdermodal.firstCurrency}
                onChange={(event, value) =>
                  dispatch(
                    setIsOpenExchangeOrderModal({
                      ...isOpenExchangeOrdermodal,
                      firstCurrency: value
                    })
                  )
                }
                options={['USD', 'USDT', 'PL']}
              />
              <StyledTextField
                width={{ xs: '288px', sm: '405px', md: '405px', lg: '405px' }}
                disableBorder
                value={isOpenExchangeOrdermodal.firstCurrencyQTT}
                onChange={(event, value) =>
                  dispatch(
                    setIsOpenExchangeOrderModal({
                      ...isOpenExchangeOrdermodal,
                      firstCurrencyQTT: event.target.value
                    })
                  )
                }
              />
            </div>
          </Stack>
        </Grid>
        
        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
        <Typography sx={{mb:2, ml:1,color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>Отримую</Typography>
          <Stack
            sx={{
              position:'relative',
              pl:2.5,
              height:'80px',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'column' },
              justifyContent:'center',
              margin: '0px 0px 15px 0px',
              borderRadius:'8px',
              background:
                'radial-gradient(4905.31% 145% at 98.67% 106.25%, rgba(229, 231, 235, 0.16) 0%, rgba(229, 231, 235, 0.20) 0.01%, rgba(229, 231, 235, 0.08) 100%)'
            }}
          >
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledAutocomplete
                value={isOpenExchangeOrdermodal.secondCurrency}
                onChange={(event, value) =>
                  dispatch(
                    setIsOpenExchangeOrderModal({
                      ...isOpenExchangeOrdermodal,
                      secondCurrency: value
                    })
                  )
                }
                options={['USD', 'USDT', 'PL']}
              />{' '}
              <StyledTextField
                disableBorder
                width={{ xs: '288px', sm: '405px', md: '405px', lg: '405px' }}
                value={isOpenExchangeOrdermodal.secondCurrencyQTT}
                onChange={(event, value) =>
                  dispatch(
                    setIsOpenExchangeOrderModal({
                      ...isOpenExchangeOrdermodal,
                      secondCurrencyQTT: event.target.value,
                      
                    })
                  )
                }
              />
            </div>
            <StyledButtonReverse left='-46px' onClick={()=>{
               dispatch(
                setIsOpenExchangeOrderModal({
                  ...isOpenExchangeOrdermodal,
                  secondCurrencyQTT: isOpenExchangeOrdermodal.firstCurrencyQTT,
                  secondCurrency: isOpenExchangeOrdermodal.firstCurrency,
                  firstCurrencyQTT: isOpenExchangeOrdermodal.secondCurrencyQTT,
                  firstCurrency: isOpenExchangeOrdermodal.secondCurrency
                })
              )
            }}/>
          </Stack>{' '}
        </Grid>

        <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'center', pt:'0 !important' }}>
          <Typography variant="body2" sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
            Курс: 1 {isOpenExchangeOrdermodal.firstCurrency} = {'1 '}
            {isOpenExchangeOrdermodal.secondCurrency} 
          </Typography>
        </Grid>
        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        Мінімальна сума, яку можна віддати:
        <br/>
        <Typography component={'span'}  variant="body2" color={theme.mainColors.error}>
        50usdt
          </Typography>
          </Typography>
        <Typography variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        Максимальна сума, яку можна віддати:
        <br/>
        <Typography component={'span'} variant="body2" sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        25000usdt
          </Typography>
          </Typography>
        </Grid>
        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        Мінімальна отримана сума:
        <br/>
        <Typography component={'span'} variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}} >
        50usdt
          </Typography>
          </Typography>
        <Typography variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        Максимальна отримана сума:
        <br/>
        <Typography component={'span'} variant="body2" sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        25000usdt
          </Typography>
          </Typography>
        <Typography variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>
        У наявності: 
        <br/>
        <Typography component={'span'} variant="body2"sx={{color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}} >
        962437
          </Typography>
          </Typography>
        </Grid>
        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', pl:'0 !important' }}>
        <Typography sx={{mb:1, ml:1,color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>E-Mail</Typography>
          <StyledTextField width = { {xs: '100%', sm: '100%', md: '100%', lg: '100%'} }/>
        </Grid>
        <Grid item lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',  }}>
        <Typography sx={{mb:1, ml:1,color: theme.selected === 'dark' ? theme.mainColors.white : '#0F0F10'}}>Карта для оплати</Typography>
          <StyledTextField  width = { {xs: '100%', sm: '100%', md: '100%', lg: '100%'} }/>
        </Grid>
        
        <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            width={{ xs: '217px', sm: '250px', md: '250px', lg: '250px' }}
            // onClick={() =>
            //   dispatch(setIsOpenExchangeOrderModal({ ...isOpenExchangeOrdermodal, isOpen: true }))
            // }
            variant="outlined"
            text="ОБМІН"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
