// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slideStyle {
  width: max-content !important;
  position: relative !important;
}
.slick-slider {
  width: 100%;
}
.slick-dots {
  z-index: 99;
}
.slideStyleDevops {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
  position: relative !important;
}
.slick-slide {
  height: max-content !important;
  margin: 0px 27px;
}

.slick-prev {
  left: -45px !important;
}
/* .slick-prev::before {
  content: url(../../assets/arrowLeft.png) !important;
}
.slick-next::before {
  content: url(../../assets/arrowRight.png) !important;
} */
.slick-next {
  left: 101% !important;
}
.slick-prev,
.slick-next {
  width: 5px !important;
  height: 5px !important;
  border-radius: 2.5px !important;
  top: 50% !important;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/Blocks/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,6BAA6B;AAC/B;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,6BAA6B;AAC/B;AACA;EACE,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;AACA;;;;;GAKG;AACH;EACE,qBAAqB;AACvB;AACA;;EAEE,qBAAqB;EACrB,sBAAsB;EACtB,+BAA+B;EAC/B,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".slideStyle {\n  width: max-content !important;\n  position: relative !important;\n}\n.slick-slider {\n  width: 100%;\n}\n.slick-dots {\n  z-index: 99;\n}\n.slideStyleDevops {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center !important;\n  justify-content: center;\n  position: relative !important;\n}\n.slick-slide {\n  height: max-content !important;\n  margin: 0px 27px;\n}\n\n.slick-prev {\n  left: -45px !important;\n}\n/* .slick-prev::before {\n  content: url(../../assets/arrowLeft.png) !important;\n}\n.slick-next::before {\n  content: url(../../assets/arrowRight.png) !important;\n} */\n.slick-next {\n  left: 101% !important;\n}\n.slick-prev,\n.slick-next {\n  width: 5px !important;\n  height: 5px !important;\n  border-radius: 2.5px !important;\n  top: 50% !important;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
