import React from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Modal, Stack, Typography } from '@mui/material';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { connect, useDispatch, useSelector } from 'react-redux';

// import { useTranslate } from 'react-redux-multilingual';
import { setIsOpenExchangeOrderModal } from '../../../redux/mainReducer';
import theme from '../../../services/theme';
import {
  StyledAutocomplete,
  StyledButton,
  StyledTextField,
  TopTitle
} from '../../styledComponents';
import './ExchangeOrderModal.css';

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: { xs: '100%', sm: '80%', md: '80%', lg: '80%' },
  bgcolor: theme.mainColors.background,
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: { xs: '20px', sm: '20px', md: '50px', lg: '60px' },
  overflow: 'scroll',
  maxHeight: { xs: '96vh', sm: '90vh', md: '80vh', lg: '90vh' },
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function ExchangeOrderModal({ isOpenModal, language, setter, data }) {
  const dispatch = useDispatch();
  const isOpenExchangeOrdermodal = useSelector((state) => state.global.isOpenExchangeOrderModal);
  // const translate = useTranslate();

  const schema = Yup.object().shape({
    name: Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string(),
    office: Yup.string(),
    currencyGive: Yup.string(),
    currencyReceive: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      office: 'Київ,Київська область, вул. Березняківська, буд. 20',
      firstCurrency: data && data.firstCurrency ? data.firstCurrency : '',
      secondCurrency: data && data.secondCurrency ? data.secondCurrency : '',
      currencyGive: data && data.firstCurrencyQTT ? data.firstCurrencyQTT : 0,
      currencyReceive: data && data.secondCurrencyQTT ? data.secondCurrencyQTT : 0
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      console.log(values);
    }
  });

  const {
    // errors,
    // touched,
    values,
    handleSubmit,
    // getFieldProps,
    // submitForm,
    resetForm,
    // setErrors,
    setFieldValue
  } = formik;
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenExchangeOrderModal({ ...isOpenExchangeOrdermodal, isOpen: false }));

          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="finlab_exchange_modal"
      >
        <Box sx={style} className="finlab_exchange_modal-box">
          <CloseIcon
            className="finlab_exchange_modal-close"
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
              color: theme.mainColors.white,
              zIndex: '99'
            }}
            onClick={() => {
              dispatch(setIsOpenExchangeOrderModal({ ...isOpenExchangeOrdermodal, isOpen: false }));
              resetForm();
            }}
          />
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Grid
                container
                // spacing={2}
                sx={{
                  display: 'flex'
                  // flexDirection: { xs: 'column-reverse', sm: 'column', md: 'column', lg: 'column' }
                }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <TopTitle isSplash={false} variant="h2">
                    Замовлення обміну
                  </TopTitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  sx={{
                    mt: { xs: 7, sm: 7, md: 7, lg: 7 },
                    display: { xs: 'unset', sm: 'unset', md: 'none', lg: 'none' }
                  }}
                >
                  <Typography>Віддаєте</Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                      alignItems: { xs: 'left', sm: 'left', md: 'center', lg: 'center' },
                      margin: '0px 0px 15px 0px'
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 0px' }}
                    >
                      <Typography>Обмін</Typography>
                      <StyledAutocomplete
                        value={values.firstCurrency}
                        onChange={(event, value) => setFieldValue('firstCurrency', value)}
                        options={['USD', 'UAH', 'PL']}
                      />
                    </div>
                    <StyledTextField
                      width={{ xs: '350px', sm: '350px', md: '405px', lg: '405px' }}
                      value={values.currencyGive}
                      onChange={(event, value) => setFieldValue('currencyGive', event.target.value)}
                    />
                  </Stack>

                  <Typography sx={{ mt: 4 }}>Отримуєте</Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                      alignItems: { xs: 'left', sm: 'left', md: 'center', lg: 'center' },
                      margin: '0px 0px 15px 0px'
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 0px' }}
                    >
                      <Typography>Обмін</Typography>
                      <StyledAutocomplete
                        value={values.secondCurrency}
                        onChange={(event, value) => setFieldValue('secondCurrency', value)}
                        options={['USD', 'UAH', 'PL']}
                      />
                    </div>{' '}
                    <StyledTextField
                      width={{ xs: '350px', sm: '350px', md: '405px', lg: '405px' }}
                      value={values.currencyReceive}
                      onChange={(event, value) =>
                        setFieldValue('currencyReceive', event.target.value)
                      }
                    />
                  </Stack>

                  <Typography variant="body2" sx={{ mt: 3 }}>
                    Курс: 1 {values.firstCurrency} = {values.secondCurrency} {'(купівля)'}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{
                      mt: 3,
                      width: { xs: '100%', sm: '83%', md: '83%', lg: '83%' },
                      display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
                    }}
                  >
                    <span style={{ color: theme.mainColors.error }}>Увага!</span> Операції можуть
                    бути обмежені у суммі. При замовленні від 1000$, ми фіксуємо курс на 30 хвилин.
                    За більш детальною інформацією звертайтеся: <br />
                    +38 (099) 715-18-01
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} sx={{ mt: 7 }}>
                  <Typography>Ваше ім'я</Typography>
                  <StyledTextField
                    width={{ xs: '350px', sm: '350px', md: '350px', lg: '405px' }}
                    placeholder="Ім'я"
                    onChange={(event, value) => setFieldValue('name', event.target.value)}
                  />
                  <Typography sx={{ mt: 4 }}>Номер телефону</Typography>
                  <StyledTextField
                    width={{ xs: '350px', sm: '350px', md: '350px', lg: '405px' }}
                    placeholder="+38 (0__) -___-__-__"
                    onChange={(event, value) => setFieldValue('phoneNumber', event.target.value)}
                  />
                  <Typography sx={{ mt: 4 }}>Ваш E-mail</Typography>
                  <StyledTextField
                    width={{ xs: '350px', sm: '350px', md: '350px', lg: '405px' }}
                    placeholder="E-mail"
                    onChange={(event, value) => setFieldValue('email', event.target.value)}
                  />
                  <Typography sx={{ mt: 4 }}>Найближче відділення</Typography>
                  <StyledAutocomplete
                    width={{ xs: '350px', sm: '350px', md: '350px', lg: '405px' }}
                    disableBorder={false}
                    value={values.office}
                    onChange={(event, value) => setFieldValue('office', value)}
                    options={[
                      'Київ,Київська область, вул. Березняківська, буд. 20',
                      'Київ,Київська область, вул. Березняківська, буд. 21',
                      'Київ,Київська область, вул. Березняківська, буд. 22'
                    ]}
                  />
                  <Typography variant="body2" sx={{ mt: 3, mb: 5 }}>
                    Курс: 1 {values.firstCurrency} = {values.secondCurrency} {'(купівля)'}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{
                      textAlign: 'center',
                      mt: 3,
                      width: { xs: '100%', sm: '83%', md: '83%', lg: '83%' },
                      display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }
                    }}
                  >
                    <span style={{ color: theme.mainColors.error }}>Увага!</span> Операції можуть
                    бути обмежені у суммі. При замовленні від 1000$, ми фіксуємо курс на 30 хвилин.
                    За більш детальною інформацією звертайтеся: <br />
                    +38 (099) 715-18-01
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{
                    mt: { xs: 1, sm: 2, md: 7, lg: 7 },
                    display: { xs: 'none', sm: 'none', md: 'unset', lg: 'unset' }
                  }}
                >
                  <Typography>Віддаєте</Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                      alignItems: { xs: 'left', sm: 'left', md: 'center', lg: 'center' },
                      margin: '0px 0px 15px 0px'
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 0px' }}
                    >
                      <Typography>Обмін</Typography>
                      <StyledAutocomplete
                        value={values.firstCurrency}
                        onChange={(event, value) => setFieldValue('firstCurrency', value)}
                        options={['USD', 'UAH', 'PL']}
                      />
                    </div>
                    <StyledTextField
                      width={{ xs: '350px', sm: '405px', md: '350px', lg: '405px' }}
                      value={values.currencyGive}
                      onChange={(event, value) => setFieldValue('currencyGive', event.target.value)}
                    />
                  </Stack>

                  <Typography sx={{ mt: 4 }}>Отримуєте</Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                      alignItems: { xs: 'left', sm: 'left', md: 'center', lg: 'center' },
                      margin: '0px 0px 15px 0px'
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 0px' }}
                    >
                      <Typography>Обмін</Typography>
                      <StyledAutocomplete
                        value={values.secondCurrency}
                        onChange={(event, value) => setFieldValue('secondCurrency', value)}
                        options={['USD', 'UAH', 'PL']}
                      />
                    </div>{' '}
                    <StyledTextField
                      width={{ xs: '350px', sm: '405px', md: '350px', lg: '405px' }}
                      value={values.currencyReceive}
                      onChange={(event, value) =>
                        setFieldValue('currencyReceive', event.target.value)
                      }
                    />
                  </Stack>

                  <Typography variant="body2" sx={{ mt: 3 }}>
                    Курс: 1 {values.firstCurrency} = {values.secondCurrency} {'(купівля)'}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{
                      mt: 3,
                      width: { xs: '100%', sm: '83%', md: '83%', lg: '83%' }
                      // display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' }
                    }}
                  >
                    <span style={{ color: theme.mainColors.error }}>Увага!</span> Операції можуть
                    бути обмежені у суммі. При замовленні від 1000$, ми фіксуємо курс на 30 хвилин.
                    За більш детальною інформацією звертайтеся: <br />
                    +38 (099) 715-18-01
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <StyledButton type="submit" variant="outlined" text="Обміняти" />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.Intl.locale,
    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(ExchangeOrderModal);
