import React from 'react';
// import { useTranslate } from 'react-redux-multilingual';
// import { Element } from 'react-scroll';

//Material
import { Box, Grid } from '@mui/material';

//Components
import { Element } from 'react-scroll';
import Slider from 'react-slick';
import vector1 from '../../../assets/Blocks/Partners/WB_1line_Logo_Black2.png';
import vector1Dark from '../../../assets/Blocks/Partners/WB_1line_Logo_Black2Dark.png';
import vector2 from '../../../assets/Blocks/Partners/image4.png';
import vector2Dark from '../../../assets/Blocks/Partners/image4Dark.png';
import vector3 from '../../../assets/Blocks/Partners/image5.png';
import vector3Dark from '../../../assets/Blocks/Partners/image5dark.png';
import vector4 from '../../../assets/Blocks/Partners/logo.png';
import vector4Dark from '../../../assets/Blocks/Partners/logoDark.png';
import vector5 from '../../../assets/Blocks/Partners/logo_kurs_ua1.png';
import vector5Dark from '../../../assets/Blocks/Partners/logo_kurs_ua1dark.png';
import vector6 from '../../../assets/Blocks/Partners/new-logo1.png';
import vector6Dark from '../../../assets/Blocks/Partners/new-logo1dark.png';

import './index.css';
import { useSelector } from 'react-redux';

export default function Partners() {
  const theme = useSelector((state) => state.global.theme);
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    useCSS: true,
    arrows: false,
    variableWidth: true

    // beforeChange: (current, next) => setActiveSlide(next)a
  };
  const cards = [
    {
      vector: vector1,
      vectorDark: vector1Dark,
    },
    {
      vector: vector2,
      vectorDark: vector2Dark,
    },
    {
      vector: vector3,
      vectorDark: vector3Dark,
    },
    {
      vector: vector4,
      vectorDark: vector4Dark,
    },
    {
      vector: vector5,
      vectorDark: vector5Dark,
    },
    {
      vector: vector6,
      vectorDark: vector6Dark,
    }
  ];

  return (
    <Grid
      item
      lg={12}
      sx={{
        pt: { xs: '50px', sm: '60px', md: '50px', lg: '70px' },
        pb: { xs: '50px', sm: '60px', md: '70px', lg: '70px' },
        paddingLeft: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
        paddingRight: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >

      <Element name="AboutUs" />
      <Grid
        container
        spacing={0}
        sx={{
          mt: 11,
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'column-reverse', md: 'row', lg: 'row' }
        }}
      >
        <Grid
          item
          md={12}
          lg={12}
          sx={{position:'relative', ":before":{

            content:'""',
            background: theme.mainColors.backgroundBox,
            position:'absolute',
            left:-10,
            width:'101%',
            height:'88px',
            borderTop: `1px solid ${theme.mainColors.gray}`,
            borderBottom: `1px solid ${theme.mainColors.gray}`,
            transform:'rotate(0.009turn)',

          }}}

        >
          <Box  sx={{
            position:'relative',
            background: theme.mainColors.backgroundBox,
            pt: 2,
            pb: 2,
            borderTop: `1px solid ${theme.mainColors.gray}`,
            borderBottom: `1px solid ${theme.mainColors.gray}`,

          }}><Slider {...settings}>
            {cards.map((el, index) => {
              return (
                <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="slideStyle"
                    // onMouseEnter={() => {
                    //   handleOpenDesc(el.desc, screenType === 'mobile' && index + 1, index);
                    // }}
                    // onMouseLeave={handleCloseDesc}
                  >
                    <img
                      style={{
                        height: '32px',
                        marginLeft: '20px',
                        marginRight: '20px'
                      }}
                      src={theme.selected === 'dark'? el.vector:el.vectorDark}
                      alt="vector"
                    />

                    {/* <Typography
                      component="span"
                      fontSize={24}
                      fontWeight={700}
                      textAlign="center"
                      sx={{ maxWidth: '300px', color:'black' }}
                      variant="body1"
                    >
                      {el.title}
                    </Typography> */}
                  </div>
                </div>
              );
            })}
          </Slider></Box>

        </Grid>
      </Grid>
    </Grid>
  );
}
