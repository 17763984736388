import { Outlet, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlActions } from 'react-redux-multilingual';

// import Background from '../assets/background.png';
// import BackgroundLight from '../assets/backgroundLight.png';
import Footer from './Footer/Footer';
import Navbar from './Header/Navbar';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function MainLayout() {
  const theme = useSelector((state) => state.global.theme);
  const RootStyle = styled('div')({
    minHeight: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    padding: '10px 0px',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // background: theme.mainColors.background
    background: theme.selected === 'dark' ? `#0f0f10` : `transparrent`
  });
  const params = useParams();
  const navigate = useNavigate();
  const { lng } = params;

  const dispatch = useDispatch();
  useEffect(() => {
    if (lng === 'ua' || lng === 'en' || lng === 'pl') {
      dispatch(IntlActions.setLocale(lng));
    } else navigate('/');
    // eslint-disable-next-line
  }, [lng]);

  return (
    <RootStyle>
      <Navbar />
      <Outlet />
      <Footer />
    </RootStyle>
  );
}

export default MainLayout;
